import { ItemNFProps } from '../../protocols/ItemNFProps';
import { TributacoesProps } from '../../protocols/TributacoesProps';

export interface IProduto {
  cod_tributacao: string;
}

export function CorrigirCfopItens(
  itens: ItemNFProps[],
  tributacoes: TributacoesProps[],
): ItemNFProps[] {
  const itensNF = itens;

  for (let i = 0; i < itensNF.length; i++) {
    const item = itens[i];

    const cliTributacao = tributacoes.find(
      (itemTributacao: TributacoesProps) =>
        itensNF[i].cod_tributacao === itemTributacao.cod_tributacao,
    );
    item.cfop = Number(item.cfop);
    if (cliTributacao) {
      if (cliTributacao.tipo_tributacao === 3 && item.cfop === 1102) {
        itensNF[i].cfop = 1403;
      }
      if (cliTributacao.tipo_tributacao === 0 && item.cfop === 1403) {
        itensNF[i].cfop = 1102;
      }
      if (cliTributacao.tipo_tributacao === 3 && item.cfop === 2102) {
        itensNF[i].cfop = 2403;
      }
      if (cliTributacao.tipo_tributacao === 0 && item.cfop === 2403) {
        itensNF[i].cfop = 2102;
      }
      if (cliTributacao.tipo_tributacao === 3 && item.cfop === 5102) {
        itensNF[i].cfop = 5405;
      }
      if (cliTributacao.tipo_tributacao === 0 && item.cfop === 5405) {
        itensNF[i].cfop = 5102;
      }
      if (cliTributacao.tipo_tributacao === 3 && item.cfop === 5152) {
        itensNF[i].cfop = 5409;
      }
      if (cliTributacao.tipo_tributacao === 0 && item.cfop === 5409) {
        itensNF[i].cfop = 5152;
      }
      if (cliTributacao.tipo_tributacao === 3 && item.cfop === 5202) {
        itensNF[i].cfop = 5411;
      }
      if (cliTributacao.tipo_tributacao === 0 && item.cfop === 5411) {
        itensNF[i].cfop = 5202;
      }
      if (cliTributacao.tipo_tributacao === 3 && item.cfop === 1202) {
        itensNF[i].cfop = 1411;
      }
      if (cliTributacao.tipo_tributacao === 0 && item.cfop === 1411) {
        itensNF[i].cfop = 1202;
      }
      if (cliTributacao.tipo_tributacao === 3 && item.cfop === 1152) {
        itensNF[i].cfop = 1409;
      }
      if (cliTributacao.tipo_tributacao === 0 && item.cfop === 1409) {
        itensNF[i].cfop = 1152;
      }
    }
  }
  return itensNF;
}
