import { GridRowParams } from '@material-ui/data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Row, Tab, Tabs } from 'react-bootstrap';

import { BiCheck } from 'react-icons/bi';
import { HiMail, HiXCircle } from 'react-icons/hi';
import { FaBook } from 'react-icons/fa';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';
import { useManutencaoFiscal } from './ManutencaoFiscalContext';
import { maskDocumento } from './function/maskDocumento';
import { Documento } from './screens/Documento';
import { Fiscal } from './screens/Fiscal';
import { NFe } from './screens/NFe';
import { ButtonForm, Container } from './styles';
import { getEspecies } from './services/getEspecie';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import DefaultLoader from '~/components/DefaultLoader';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { AiFillSave } from 'react-icons/ai';
import { toast } from 'react-toastify';

export const ManutencaoFiscalContent: React.FC = () => {
  const [tipoEntrada, setTipoEntrada] = useState<string>('');
  const [especie, setEspecie] = useState<SelectType[]>([]);
  const [flgCancelada, setFlgCancelada] = useState<boolean>(false);
  const {
    update,
    setUpdate,
    showSearch,
    setShowSearch,
    onClearForm,
    currentTab,
    setCurrentTab,
    setValue,
    getFiscal,
    handleNfe,
    getValues,
    handleFlgProdutor,
    handleConferido,
    handleCFOPS,
    handleTributacoes,
    estados,
    conferida,
    setConferida,
    setInitEspecie,
    setInitEstado,
    onSave,
    handleNfAnt,
    handleTipoEmitente,
    handleTipoEmissao,
    handleEspecie,
    handleNcm,
    setLoader,
    loader,
    errors,
    flgLivro,
    setFlgLivro,
    handleFlgLivro,
    cidade,
    setCidade,
    clearCidade,
    setClearCidade,
  } = useManutencaoFiscal();

  useEffect(() => {
    (async () => {
      const esp = await getEspecies();
      setEspecie(esp);
    })();
  }, []);

  useEffect(() => {
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) setCurrentTab('documento');
  }, [errors, setCurrentTab]);

  const onRowClick = async (param: GridRowParams) => {
    const {
      dta_emissao,
      dta_entrada,
      num_serie_nf,
      des_especie,
      cod_especie,
      num_nf,
      val_total_nf,
      des_natureza,
      num_chave_acesso,
      des_razao_social,
      num_cpf_cnpj,
      num_rg_ie,
      num_fone,
      des_endereco,
      num_endereco,
      des_bairro,
      des_cidade,
      num_cep,
      des_uf,
      num_municipio,
      des_obs_fiscal,
      cod_seq_nf,
      flg_interestadual,
      tipo_operacao,
      flg_cliente,
      cod_xml,
      cod_loja,
      cod_perfil,
      flg_conferida,
      flg_produtor,
      num_serie_produtor,
      des_especie_produtor,
      num_nf_produtor,
      dta_emissao_produtor,
      num_cpf_cnpj_produtor,
      num_rg_ie_produtor,
      des_uf_produtor,
      num_chave_produtor,
      flg_cancelada,
      flg_confirmar,
      flg_complementar,
      tipo_emitente,
      tipo_emissao,
      flg_livro,
      tipo_pessoa,
    } = param.row;

    setValue('tipo_pessoa', tipo_pessoa);
    setFlgLivro(flg_livro);
    handleTipoEmitente(tipo_emitente);
    handleTipoEmissao(tipo_emissao);

    const uf = estados.filter((estado: any) => estado.label === des_uf);
    const ufProdutor = estados.filter(
      (estado: any) => estado.label === des_uf_produtor,
    );

    if (uf.length > 0) {
      setCidade({
        cidade: des_cidade ? des_cidade.trim() : '',
        uf: uf[0].label,
      });
    }

    setValue('estado_produtor', ufProdutor[0]);
    setFlgCancelada(flg_cancelada);
    setValue('flg_cancelada', flg_cancelada);
    setValue('flg_confirmar', flg_confirmar);
    setValue('flg_complementar', flg_complementar);
    setValue('num_serie_produtor', num_serie_produtor);
    setValue('des_especie_produtor', des_especie_produtor);
    setValue('num_nf_produtor', num_nf_produtor);
    setValue(
      'dta_emissao_produtor',
      dta_emissao_produtor
        ? moment(dta_emissao_produtor).format('YYYY-MM-DD')
        : null,
    );
    setValue(
      'num_cpf_cnpj_produtor',
      maskDocumento(String(num_cpf_cnpj_produtor)),
    );
    setValue('num_rg_ie_produtor', num_rg_ie_produtor);
    setValue('num_chave_produtor', num_chave_produtor);
    setValue('cod_seq_nf', cod_seq_nf);
    setValue('flg_conferida', flg_conferida);
    setConferida(flg_conferida);
    setValue('cod_loja', cod_loja);
    setValue('cod_perfil', cod_perfil);
    setValue('dtaEmissao', moment(dta_emissao).format('YYYY-MM-DD'));
    setValue('dtaEntrada', moment(dta_entrada).format('YYYY-MM-DD'));

    setValue('numSerieNF', num_serie_nf);
    setValue(
      'especie',
      especie.find(
        (item: any) => Number(item.cod_especie) === Number(cod_especie),
      ),
    );
    handleNfAnt(num_nf, num_serie_nf);
    setValue('num_nf', num_nf);
    setValue('total_nf', val_total_nf);
    setValue('natureza', des_natureza);
    setValue('chave_nfe', num_chave_acesso);
    setValue('razao_social', des_razao_social);
    setValue('num_documento', maskDocumento(String(num_cpf_cnpj)));
    setValue('ie_rg', num_rg_ie);

    if (flg_conferida) {
      const digits = String(num_fone).replace(/\D/g, '');
      const formattedNumber = `(${digits.slice(0, 2)}) ${digits.slice(
        2,
        6,
      )}-${digits.slice(6)}`;
      if (formattedNumber.length === 14) {
        setValue('telefone', formattedNumber);
      }

      const cepDigito = String(num_cep).replace(/\D/g, '');

      // Formatar o CEP no padrão desejado
      const formattedCep = `${cepDigito.slice(0, 5)}-${cepDigito.slice(5)}`;
      if (formattedCep.length === 9) {
        setValue('cep', formattedCep);
      }
    } else {
      setValue('telefone', num_fone);
      setValue('cep', num_cep);
    }

    setValue('endereco', des_endereco);
    setValue('numero', num_endereco);
    setValue('bairro', des_bairro);
    setValue('cod_municipio', num_municipio);
    setValue('observacao', des_obs_fiscal);
    setValue('flg_interestadual', flg_interestadual);
    setValue('tipo_operacao', tipo_operacao);
    setValue('flg_cliente', flg_cliente);

    setTipoEntrada(tipo_operacao === 0 ? 'Entrada' : 'Saída');

    getFiscal();
    handleNfe(cod_xml);
    handleCFOPS(flg_interestadual, tipo_operacao, flg_cliente);
    handleEspecie();
    handleNcm();
    handleTributacoes();

    setLoader(true);
    setUpdate(true);
    handleFlgProdutor(flg_produtor);
    setShowSearch(false);
    setLoader(false);

    if (flg_cancelada) {
      toast.warn('Alteração não permitida, NF Cancelada!');
    }
  };
  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={272}
          onlyReport
        />
      )}
      {!showSearch && (
        <>
          <FormDefault
            codTela={272}
            title="Manutenção Fiscal"
            codigoRegistro={[
              { value: getValues('cod_seq_nf'), des_campo: 'Código' },
            ]}
            onSave={() => Promise.resolve()}
            onCancel={() => {
              onClearForm();
              setFlgCancelada(false);
              setShowSearch(true);
            }}
            onClearFields={() => {
              setFlgCancelada(false);
              onClearForm();
            }}
            isUpdate={update}
            onNew={() => onClearForm()}
            isNew={false}
            onDelete={() => false}
            isSave
            isDelete={false}
            isDisabledSave={conferida}
            onReturnSearch={() => {
              onClearForm();
              setFlgCancelada(false);
              setShowSearch(true);
            }}
            showSwitch={false}
          >
            <h3
              style={{
                fontSize: '20px',
                marginBottom: '20px',
                marginTop: '20px',
              }}
            >
              {`${tipoEntrada} | Lj: ${getValues(
                'cod_loja',
              )} | Perfil: ${getValues('cod_perfil')}  ${getValues(
                'natureza',
              )} |`}{' '}
              {conferida && (
                <IoCheckmarkCircleSharp
                  style={{ marginTop: '-5px' }}
                  color="green"
                />
              )}{' '}
              {conferida ? 'Conferida' : 'Não conferida'}{' '}
              {flgLivro ? (
                <>
                  {' '}
                  |
                  <IoCheckmarkCircleSharp
                    style={{ marginTop: '-5px' }}
                    color="green"
                  />{' '}
                </>
              ) : (
                <>
                  {' '}
                  | <HiXCircle color="red" style={{ marginTop: '-5px' }} />
                </>
              )}{' '}
              Livro Fiscal
              {flgCancelada && (
                <>
                  | <HiXCircle color="red" style={{ marginTop: '-5px' }} />{' '}
                  Cancelada
                </>
              )}{' '}
            </h3>

            <Tabs
              id="controlled-NF"
              activeKey={currentTab}
              onSelect={(tab) => setCurrentTab(tab ?? 'documento')}
            >
              <Tab eventKey="documento" title="Documento">
                <Documento />
              </Tab>
              <Tab eventKey="fiscal" title="Fiscal">
                <Fiscal />
              </Tab>
              <Tab eventKey="nfe" title="NFe">
                <NFe />
              </Tab>
            </Tabs>
          </FormDefault>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '40px',
            }}
            className="align-items-center"
          >
            <div className="buttonContainer">
              <ButtonForm onClick={handleFlgLivro} className="livro_fiscal">
                <FaBook fontSize="20px" color="#fff" />
                Gera p/ Livro Fiscal?
              </ButtonForm>
            </div>
            <div className="buttonContainer">
              <ButtonForm onClick={handleConferido} className="conferido">
                <BiCheck fontSize="20px" color="#fff" />
                {`${conferida ? 'Desconferir' : 'CONFERIDO?'}`}
              </ButtonForm>
            </div>
            <div className="buttonContainer">
              <ButtonForm
                onClick={() => {
                  onClearForm();
                  setShowSearch(true);
                }}
                className="cancelar"
              >
                CANCELAR
              </ButtonForm>
            </div>
            <div className="buttonContainerGravar">
              <ButtonForm
                onClick={() => {
                  if (flgCancelada) {
                    toast.warn('Alteração não permitida, NF Cancelada!');
                    return;
                  }
                  if (!conferida) {
                    setInitEspecie(true);
                    setInitEstado(true);

                    onSave();
                  }
                }}
                className={conferida ? 'gravar disabled' : 'gravar'}
              >
                <AiFillSave size={25} /> Gravar
              </ButtonForm>
            </div>
          </Row>
        </>
      )}
    </Container>
  );
};
